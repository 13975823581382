import _ from "lodash";

export const emptyLineup = {
  pitcher: undefined,
  catcher: undefined,
  shortStop: undefined,
  firstBase: undefined,
  secondBase: undefined,
  thirdBase: undefined,
  outfield1: undefined,
  outfield2: undefined,
  outfield3: undefined,
  outfield4: undefined,
};

export const POSITIONS = {
  pitcher: "pitcher",
  catcher: "catcher",
  firstBase: "firstBase",
  secondBase: "secondBase",
  thirdBase: "thirdBase",
  shortStop: "shortStop",
  outfield1: "outfield1",
  outfield2: "outfield2",
  outfield3: "outfield3",
  outfield4: "outfield4",
  bench: "bench",
  outfield: "outfield",
  any: "any",
};

const initialEligiblePositions = {
  [POSITIONS.pitcher]: true,
  [POSITIONS.catcher]: true,
  [POSITIONS.firstBase]: true,
  [POSITIONS.secondBase]: true,
  [POSITIONS.thirdBase]: true,
  [POSITIONS.shortStop]: true,
  [POSITIONS.outfield]: true,
};

export const Players = {
  blakeShaw: {
    name: "Blake Shaw",
    jerseyNum: 26,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  brysonAhlstedt: {
    name: "Bryson Ahlstedt",
    jerseyNum: 24,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  carterBrooks: {
    name: "Carter Brooks",
    jerseyNum: 17,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  jackVautour: {
    name: "Jack Vautour",
    jerseyNum: 22,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  jasonMacPhearson: {
    name: "Jason MacPhearson",
    jerseyNum: 8,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  johnStewart: {
    name: "John Stewart",
    jerseyNum: 4,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  matthewShortt: {
    name: "Matthew Shortt",
    jerseyNum: 48,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  michaelBoyd: {
    name: "Michael Boyd",
    jerseyNum: 28,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  ostopMostowy: {
    name: "Ostop Mostowy",
    jerseyNum: 9,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  philPavilich: {
    name: "Phil Pavilich",
    jerseyNum: 11,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  ronanWeir: {
    name: "Ronan Weir",
    jerseyNum: 59,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  ryanShortt: {
    name: "Ryan Shortt",
    jerseyNum: 73,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  spencerDobbs: {
    name: "Spencer Dobbs",
    jerseyNum: 19,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  tylerVerbanac: {
    name: "Tyler Verbanac",
    jerseyNum: 27,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
  zachCowan: {
    name: "Zach Cowan",
    jerseyNum: 23,
    hasPitched: false,
    hasSat: false,
    hasCaught: false,
    eligiblePositions: _.cloneDeep(initialEligiblePositions),
  },
};

export const LockedPositionsOptions = [
  { label: "Any", id: POSITIONS.any },
  { label: "Pitcher", id: POSITIONS.pitcher },
  { label: "Catcher", id: POSITIONS.catcher },
  { label: "Short Stop", id: POSITIONS.shortStop },
  { label: "First Base", id: POSITIONS.firstBase },
  { label: "Second Base", id: POSITIONS.secondBase },
  { label: "Third Base", id: POSITIONS.thirdBase },
  { label: "Outfield", id: POSITIONS.outfield },
];

export const PositionIdToLabelMap = {
  [POSITIONS.pitcher]: "Pitcher",
  [POSITIONS.catcher]: "Catcher",
  [POSITIONS.firstBase]: "1B",
  [POSITIONS.secondBase]: "2B",
  [POSITIONS.thirdBase]: "3B",
  [POSITIONS.shortStop]: "SS",
  [POSITIONS.outfield1]: "OF",
  [POSITIONS.outfield2]: "OF",
  [POSITIONS.outfield3]: "OF",
  [POSITIONS.outfield4]: "OF",
  [POSITIONS.bench]: "",
};

export const OutfieldPositions = [
  POSITIONS.outfield1,
  POSITIONS.outfield2,
  POSITIONS.outfield3,
  POSITIONS.outfield4,
];
